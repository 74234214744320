$background-active: rgba(250, 250, 250, 0.2);
$primary: rgba(250, 250, 250, 0.1);
$trans: 0.3s;

.goTop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 3em;
  height: 3em;
  background-color: $primary;
  border-radius: 5px;
  transition: $trans;

  &:hover {
    background-color: $background-active;
    transition: $trans;
  }

  a {
    font-size: 2em;
    display: block;
    width: 100%;
    height: 100%;
    background: url('./icon/go-top.svg') no-repeat center center;
    opacity: 0.2;
    transition: $trans;

    &:hover {
      opacity: 0.4;
      transition: $trans;
      background: url('./icon/go-top.svg') no-repeat center 46%;
    }
  }
}
