$background-active: rgba(250, 250, 250, 0.2);
$primary: rgba(250, 250, 250, 0.1);

.tabs {
  input {
    position: absolute;
    opacity: 0;
    background: $primary;
    transition: 0.3s;
  }

  label {
    background: $primary;
    color: #f5a425;
    margin: 0;
    width: 100%;
    cursor: pointer;

    &:hover {
      background: $primary;
    }

    &:active {
      background: $background-active;
    }
  }

  input:focus + label {
    color: #495057;
    background-color: $background-active;
    z-index: 1;
  }

  input:checked + label {
    background: $background-active;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
  }
  &__panel {
    color: #fff;
    background-color: rgba(250, 250, 250, 0.2);
    border-top: 1px solid $background-active;
    display: none;
    padding: 20px 30px 30px;

    img {
      width: 48%;
      object-fit: cover;
    }

    & > div {
      width: 48%;
    }

    h4 {
      margin-top: 20px;
      font-size: 30px;
      color: #fff;
      font-weight: 800;
      letter-spacing: 0.5px;
      line-height: 40px;
      margin-bottom: 20px;
      em {
        font-style: normal;
        color: #f5a425;
      }
    }
    p {
      text-align: justify;
    }
  }
  input:checked + label + &__panel {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 950px) {
    input:checked + label + &__panel {
      display: block;
    }
    &__panel {
      img {
        width: 100%;
      }

      & > div {
        width: 100%;
      }
    }
  }
}
