.root {
  background-color: rgba(250, 250, 250, 0.1);
  padding: 60px 40px;
  width: 100%;
  text-align: center;

  input {
    width: 100%;
    //height: 40px;
    background-color: rgba(250, 250, 250, 0.1);
    border-radius: 0px;
    border: none;
    outline: none;
    color: #fff;
    /* font-size: 13px; */
    margin-bottom: 20px;
    letter-spacing: 0.5px;
    font-size: 1rem;

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
      color: #fff;
      background-color: rgba(250, 250, 250, 0.1);
    }

    &::placeholder,
    textarea::placeholder {
      color: #fff;
    }

    &[type='radio'],
    &[type='checkbox'] {
      width: 15px;
      height: 15px;
      display: inline-block;
    }

    &:checked::parent {
      background-color: #f5a425;
    }
  }

  button {
    margin-top: 10px;
    background-color: #f5a425;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 700;
    padding: 12px 20px;
    display: inline-block;
    outline: none;
    box-shadow: none;
    border: none;
  }

  label {
    width: 100%;
    text-align: left;
    line-height: 40px;
    margin: 0;
    padding: 2px 15px;

    &:has(input:checked) {
      border: 2px solid #f5a425;
      padding: 0 13px;
    }
    // p {
    //   line-height: 0;
    //   text-align: center;
    // }
  }

  select {
    width: 100%;
    height: 38px;
    background-color: rgba(250, 250, 250, 0.1);
    border-radius: 0px;
    border: none;
    outline: none;
    overflow: hidden;
    box-shadow: none;
    color: #fff;
    margin: 0 0 20px 0;
    padding-left: 10px;
    letter-spacing: 0.5px;
    font-size: 1rem;

    option {
      background: rgba(#172238, 0.8);
      &:checked {
        background: gray;
        // color: #172238;
      }
    }
  }

  textarea,
  textarea:focus {
    width: 100%;
    background-color: rgba(250, 250, 250, 0.1);
    border-radius: 0px;
    border: none;
    outline: none;
    overflow: hidden;
    box-shadow: none;
    color: #fff;
    margin: 0 0 20px 0;
    padding-top: 10px;
    letter-spacing: 0.5px;
    font-size: 1rem;
  }

  :global {
    .who-help {
      display: flex;
      justify-content: space-between;
      @media (max-width: 991px) {
        & {
          flex-direction: column;
        }
      }
    }
    .method-pay {
      display: flex;
      justify-content: space-evenly;
      @media (max-width: 768px) {
        & {
          flex-direction: column;
        }
      }
    }
    .currency-pay {
      display: flex;
      justify-content: space-evenly;
      @media (max-width: 450px) {
        & {
          flex-direction: column;
        }
      }
    }
  }
}
.radioInputs {
  width: 100%;
  padding: 0 10px;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 0px;
  border: none;
  outline: none;
  color: #fff;
  margin: 0 0 20px 0;
  letter-spacing: 0.5px;
  font-size: 1rem;
}
